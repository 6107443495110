import { ReactElement, ReactNode } from "react";

export default function ScrollToTop({
    children,
    className,
    colors,
}: {
    children: ReactNode;
    className?: string;
    colors?: {
        bg: string;
        text: string;
    };
}): ReactElement {
    const scrollToTop = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };
    return (
        <button
            onClick={() => scrollToTop()}
            style={{
                backgroundColor: colors?.bg ?? "black",
                color: colors?.text ?? "grey",
            }}
            className={`px-8 py-3 hover:opacity-90 transition-opacity ${
                className ?? ""
            }`}
        >
            {children}
        </button>
    );
}
